import React, { useEffect, useRef } from "react";
import { ListSkeleton } from "../../skeletons/List";
import axios from "axios";
import { useState } from "react";
import DataTable from "../../components/Table";
import { Autocomplete, Backdrop, Button, Card, Container, FormControl, IconButton, InputLabel, MenuItem, MenuList, Modal, Paper, Select, TextField, Tooltip, Typography} from "@mui/material";
import FloatingActionButtons from "../../components/Fab";
import { useDashboardDrawer } from "../../providers/consumers/useDashboardDrawer";
import { useBuilder } from "../../../hooks/useBuilder";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import { GridActionsCellItem, useGridApiRef } from "@mui/x-data-grid";
import { Box } from "@mui/system";
import store, { clear, setFieldState } from "../../store/formStore";
import { useSnackBar } from "../../providers/consumers/useSnackBar";
import { AuthHelper } from "../../controllers/AuthHelper";
import { LoadingButton } from "@mui/lab";
import GzipHelper from "../../controllers/GzipHelper";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CancelIcon from '@mui/icons-material/Cancel';
import "moment/locale/it";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import agenzie from '../../../assets/json/agenzie.json';
import { BrowserView, MobileView } from "react-device-detect";

const statuses_admin = ["DA CARICARE",
    "KO",
    "DA VALIDARE BO",
    "PLICO NON RICEVUTO",
    "RECUPERATO",
    "CARICATO",
    "CONSEGNATO",
    "NON VALIDATO",
];
const statuses_bo_agenzia = ["DA CARICARE",
"KO AGENZIA",
"DA VALIDARE BO",
"PLICO NON RICEVUTO",
"RECUPERATO",
"CARICATO",
"NON VALIDATO"
];
const statuses_agent = [
    "DA VALIDARE BO",
    "RECUPERATO",
    "NON VALIDATO"
];
const statuses_agency = [
    "DA CARICARE",
    "DA VALIDARE BO",
    "RECUPERATO",
    "CONSEGNATO",
    "KO AGENZIA"
];

const style = {

    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 400,
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

const monthMap = {
  1: 'Gennaio',
  2: 'Febbraio',
  3: 'Marzo',
  4: 'Aprile',
  5: 'Maggio',
  6: 'Giugno',
  7: 'Luglio',
  8: 'Agosto',
  9: 'Settembre',
  10: 'Ottobre',
  11: 'Novembre',
  12: 'Dicembre'
}
const excludeFromView = [
  'agency',
  'assigned',
  'POD',
  'PDR',
  'Nome cognome IBAN',
  'IBAN',
  'Competenza',
  'Note stato maurizon',
  'status_maurizon'
]

const includeInExportConditions = [
  "POD",
  "PDR",
  "RID",
  "Bolletta Web",
  "Agenzia",
  "Stato Maurizon",
  "Stato Finale",
  "Tipologia cliente",
  "Azione Commerciale",
  "Fornitura"
]

const ListTelefonia = () => {
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(false);
    const { pushMobileOpen, defaultOpen, addRouter } = useDashboardDrawer();
    const [selectedLead, setSelectedLead] = useState(null);
    const [loadingButton, setLoadingButton] = useState(false);
    const {builder, form, refs} = useBuilder();
    const [lists, setLists] = useState([]);
    const [selectedContracts, setSelectedContracts] = useState([]);
    const [users, setUsers] = useState([]);

    const [open, setOpen] = useState(false);

    const navigate = useNavigate();
    const { addAlert } = useSnackBar();

    const [matricola, setMatricola] = useState(null);
    const [userAttributes, setUserAttributes] = useState([]);
    const [committenti, setCommittenti] = useState([]);

    const [selectedCommittente, setSelectedCommittente] = useState("");
    const [role, setRole] = useState("");

    const [usersRemap, setUserRemap] = useState([]);

    const [showAdminTable, setShowAdminTable] = useState(false);

    const [lastName, setLastName] = useState("");
    const [dateFilter, setDateFilter] = React.useState({
      from: moment().subtract(1, 'days'),
      to: moment()
    })
    const [status, setStatus] = useState("");

    const [selectedContractLead, setSelectedContractLead] = useState({});

    const getRole = async() => {
        const attributes = await AuthHelper.getUserFromIdToken();
        console.log('ruolo', attributes.UserAttributes.filter(x => x.Name === 'custom:roles')[0].Value);
        setRole(attributes.UserAttributes.filter(x => x.Name === 'custom:roles')[0].Value);
        if(attributes.UserAttributes.filter(x => x.Name === 'custom:roles')[0].Value){
          getCustomers();
        }
        return attributes.UserAttributes.filter(x => x.Name === 'custom:roles')[0].Value;
    }


    const comparator = (v1, v2) => moment(v1, 'DD/MM/YYYY HH:mm:SS') - moment(v2, 'DD/MM/YYYY HH:mm:SS');

    const columnsAgents = [
        { field: 'id', headerName: 'ID', width: 125, headerClassName: 'data-grid-header' },
        { field: 'Plico', headerName: 'Plico', width: 200, headerClassName: 'data-grid-header'},
        { field: 'Denominazione', headerName: 'Denominazione', width: 200, headerClassName: 'data-grid-header', valueGetter: (params) => params.row.Denominazione},
        { field: 'leadObject.email', valueGetter: (params) => params.row.email, headerName: 'E-mail', width: 200, headerClassName: 'data-grid-header' },
        { field: 'POD', headerName: 'POD', width: 125, headerClassName: 'data-grid-header'},
        { field: 'PDR', headerName: 'PDR', width: 125, headerClassName: 'data-grid-header'},
        { field: 'status_maurizon', headerName: 'Stato Maurizon', width: 200, headerClassName: 'data-grid-header'},
        { field: 'status_offer', headerName: 'Stato Offerta', width: 200, headerClassName: 'data-grid-header'},
        { field: 'status_final', headerName: 'Stato Finale', width: 200, headerClassName: 'data-grid-header'},
        { field: 'assigned', headerName: 'Assegnato a', width: 200, headerClassName: 'data-grid-header', renderCell: (params) => (
          <Tooltip title={`${usersRemap?.filter(x => params.row.assigned && params.row.assigned !== "null" ? x.sub === params.row.assigned : x.sub === params.row.assignedAgent).length > 0 && usersRemap?.filter(x => params.row.assigned && params.row.assigned !== "null"  ? x.sub === params.row.assigned : x.sub === params.row.assignedAgent)[0]?.name} ${usersRemap?.filter(x => params.row.assigned && params.row.assigned !== "null"  ? x.sub === params.row.assigned : x.sub === params.row.assignedAgent).length > 0 && usersRemap?.filter(x => params.row.assigned && params.row.assigned !== "null" ? x.sub === params.row.assigned : x.sub === params.row.assignedAgent)[0]?.family_name}`}>
            <span>{params.row.assigned ? params.row.assigned : params.row.assignedAgent}</span>
          </Tooltip>
        )},
        { field: 'lead', headerName: 'ID Lead', width: 200, headerClassName: 'data-grid-header'},
        { field: 'agency', headerName: 'Agenzia', width: 200, headerClassName: 'data-grid-header' },
        { field: 'Tipologia Cliente', headerName: 'Tipologia cliente', width: 185},
        { field: 'P.I/Codice Fiscale', headerName: 'P.I/Codice Fiscale', valueGetter: (params) => params.row["P.I/Codice Fiscale"], width: 200, headerClassName: 'data-grid-header'},
        { field: 'Offerta', headerName: 'Offerta', width: 200, headerClassName: 'data-grid-header' },
        { field: 'Indirizzo', headerName: 'Indirizzo', valueGetter: (params) =>params.row['Indirizzo societa'] ? params.row['Indirizzo societa'] : params.row['Indirizzo'], width: 200, headerClassName: 'data-grid-header'},
        { field: 'Competenza', headerName: 'Mese Competenza', width: 200, valueGetter: (params) => params.row['Competenza'] ? params.row['Competenza'] : `${monthMap[parseInt(params.row.createdAt.split(' ')[0].split('/')[1])]} ${params.row.createdAt.split(' ')[0].split('/')[2]}`},
        { field: 'createdAt', headerName: 'Data creazione', width: 200, headerClassName: 'data-grid-header', sortComparator: comparator },
    ];

const sequelizeColumns = [
    { name: 'id', type: "" },
    { name: 'Tipologia Cliente', type: "" },
    { name: 'Azione Commerciale', type: "" },
    { name: 'Codice Migrazione', type: "" },
    { name: 'Indirizzo', type: "" },
    { name: 'Provincia', type: "" },
    { name: 'CAP', type: "" },
    { name: 'assigned'},
    { name: 'Indirizzo spedizione modem', type: "" },
    { name: 'Telefono aggiuntivo', type: "" },
    { name: 'P.I/Codice Fiscale', type: "" },
    { name: 'Offerta', type: "" },
    { name: 'RID', type: "" },
    { name: 'Denominazione', type: "" }
  ];
   
  const columnsAdmin = [
    { field: 'actions',
      type: 'actions',
      getActions: (params) => [
        <GridActionsCellItem onClick={async() => {
          let _contract = customers.filter(x => x.id === params.row.id)[0]
          const res = await (await fetch(`https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/lead/${_contract.lead}`)).json();
          const unzipped = await GzipHelper.unzip(res)
          setSelectedContractLead({...unzipped});
          setBackdropOpen(true); setContractSelected({..._contract}); setContractEdit(false)
        
        }} label="Visualizza" showInMenu />,
        <GridActionsCellItem onClick={() => {setBackdropOpen(true); setContractSelected({...customers.filter(x => x.id === params.row.id)[0]}); setContractEdit(true)}} label="Modifica" showInMenu />,
        <GridActionsCellItem onClick={() => {
          let _contract = customers.filter(x => x.id === params.row.id)[0]
          openAddContract(_contract)}} label="Duplica" showInMenu />,
      ],headerName: 'Azioni', width: 85, headerClassName: 'data-grid-header'
    },
    { field: 'id', headerName: 'ID', width: 125, headerClassName: 'data-grid-header' },
    { field: 'Azione Commerciale', headerName: 'Azione Commerciale', width: 200, headerClassName: 'data-grid-header' },
    { field: 'Tipologia Cliente', headerName: 'Tipologia cliente', width: 185},
    { field: 'createdAt', headerName: 'Data inserimento', width: 200, headerClassName: 'data-grid-header', sortComparator: comparator},
    { field: 'agency', headerName: 'Agenzia', width: 200, headerClassName: 'data-grid-header' },
    { field: 'assigned', headerName: 'Assegnato a', width: 200, headerClassName: 'data-grid-header', renderCell: (params) => (
      <Tooltip title={`${usersRemap?.filter(x => params.row.assigned && params.row.assigned !== "null" ? x.sub === params.row.assigned : x.sub === params.row.assignedAgent).length > 0 && usersRemap?.filter(x => params.row.assigned && params.row.assigned !== "null"  ? x.sub === params.row.assigned : x.sub === params.row.assignedAgent)[0]?.name} ${usersRemap?.filter(x => params.row.assigned && params.row.assigned !== "null"  ? x.sub === params.row.assigned : x.sub === params.row.assignedAgent).length > 0 && usersRemap?.filter(x => params.row.assigned && params.row.assigned !== "null" ? x.sub === params.row.assigned : x.sub === params.row.assignedAgent)[0]?.family_name}`}>
        <span>{params.row.assigned && params.row.assigned !== "null" ? params.row.assigned : params.row.assignedAgent}</span>
      </Tooltip>
    )},
    { field: 'Plico', headerName: 'Plico', width: 200, headerClassName: 'data-grid-header'},
    { field: 'Committente', headerName: 'Committente', width: 200, headerClassName: 'data-grid-header'},
    { field: 'Copertura', headerName: 'Copertura', width: 200, headerClassName: 'data-grid-header'},
    { field: 'Instant', headerName: 'Instant', valueGetter: (params) => params.row["Instant"] ? "Si" : "No", width: 200, headerClassName: 'data-grid-header'},
    { field: 'P.I/Codice Fiscale', headerName: 'P.I/Codice Fiscale', valueGetter: (params) => params.row["P.I/Codice Fiscale"], width: 200, headerClassName: 'data-grid-header'},
    { field: 'Denominazione', headerName: 'Denominazione', width: 200, headerClassName: 'data-grid-header', valueGetter: (params) => params.row.Denominazione},
    { field: 'Tipologia fornitura', headerName: 'Fornitura', width: 200, headerClassName: 'data-grid-header', valueGetter: (params) => 'Fibra'},
    { field: 'Codice Migrazione', headerName: 'Codice Migrazione', width: 125, headerClassName: 'data-grid-header'},
    { field: 'rid', headerName: 'RID', valueGetter: (params) => params.row['rid'] === 'on' ? 'Si' : 'No', width: 125, headerClassName: 'data-grid-header'},
    { field: 'Bolletta Web', headerName: 'Bolletta Web', valueGetter: (params) => params.row['Bolletta Web'] === 'on' ? 'Si' : 'No', width: 125, headerClassName: 'data-grid-header'},
    { field: 'Offerta', headerName: 'Offerta', width: 200, headerClassName: 'data-grid-header' },
    { field: 'Telefono', headerName: 'Telefono', valueGetter: (params) => params.row.phone, width: 200, headerClassName: 'data-grid-header' },
    { field: 'Telefono Aggiuntivo', headerName: 'Telefono Aggiuntivo', width: 200, headerClassName: 'data-grid-header' },
    { field: 'Competenza', headerName: 'Mese Competenza', width: 200, valueGetter: (params) => params.row['Competenza'] ? params.row['Competenza'] : `${monthMap[parseInt(params.row.createdAt.split(' ')[0].split('/')[1])]} ${params.row.createdAt.split(' ')[0].split('/')[2]}`},
    { field: 'status_maurizon', headerName: 'Stato Maurizon', width: 200, headerClassName: 'data-grid-header'},
    { field: 'Note stato maurizon', headerName: 'Note stato maurizon', width: 200, headerClassName: 'data-grid-header'},
    { field: 'status_offer', headerName: 'Stato Offerta', width: 200, headerClassName: 'data-grid-header'},
    { field: 'causale', headerName: 'Causale annullamento', width: 200, headerClassName: 'data-grid-header'},
    { field: 'status_final', headerName: 'Stato Finale', width: 200, headerClassName: 'data-grid-header'},
    { field: 'Provincia', headerName: 'Provincia', valueGetter: (params) => params.row['Provincia societa'] ? params.row['Provincia societa'] : params.row['Provincia'], width: 200, headerClassName: 'data-grid-header'},
    { field: 'CAP', headerName: 'CAP', valueGetter: (params) => params.row['CAP'] ? params.row['CAP'] : params.row['CAP societa'], width: 200, headerClassName: 'data-grid-header'},
    { field: 'Indirizzo', headerName: 'Indirizzo', valueGetter: (params) =>params.row['Indirizzo societa'] ? params.row['Indirizzo societa'] : params.row['Indirizzo'], width: 200, headerClassName: 'data-grid-header'},
    { field: 'leadObject.email', valueGetter: (params) => params.row.email, headerName: 'E-mail', width: 200, headerClassName: 'data-grid-header' },
    { field: 'Inbound', headerName: 'Inbound', valueGetter: (params) => params.row['Inbound'] === 'on' ? 'Si' : 'No', width: 200, headerClassName: 'data-grid-header' },    
  ];
  
  
  const getCommittenti = async() => {
        const res = await axios.get('https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/customer');
        const unzipped = await GzipHelper.unzip(res.data);
        setCommittenti([...unzipped.filter(x => x.type === 'Telefonia')]);
    }
    const assignContracts = async() => {
      setLoadingButton(true);
      try{
        const body = {
          contracts: [...selectedContracts],
          assignerId: (await AuthHelper.getUserFromIdToken()).UserAttributes.filter(x => x.Name === 'sub')[0].Value.split('-')[0],
          matricola: matricola.sub,
          'custom:vendor': matricola['custom:vendor']
        };
  
        const res = await fetch('https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/contracts/assign', {body: JSON.stringify(body), method: 'PUT'});
  
        setLoadingButton(false);
        setOpen(false);
        addAlert({
          message: 'Il contratto è stato modificato',
          severity: 'success'
        });
        window.location.reload();
      }catch(ex){
        setOpen(false);
        setLoadingButton(false);
        addAlert({
          message: 'Errore server. Riprova più tardi o contatta il reparto tecnico.',
          severity: 'error'
        });
      }
      

    }
    const getUsers = async() => {
      // const authIstance = AuthHelper.getInstance();
      const role = await getRole();

      const _users = await AuthHelper.getUsers();
      if(_users){
        const _remap = [..._users.map(x => x.Attributes.map(y => ({[y.Name]: y.Value})))];
        let _usersRemapped = [];
        for(let _remapped of _remap){
            const _single_user = {};
            for(let object of _remapped){
                _single_user[Object.keys(object)[0]] = object[Object.keys(object)[0]];
                if(Object.keys(object)[0] === 'sub'){
                    _single_user.id = object.sub;
                }
            }
            let user = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user'))['Attributes'] : (await AuthHelper.getUserFromIdToken()).Attributes;

            switch(role){
              case 'OPERATORE BO':
                  _usersRemapped.push(_single_user);
                /**
                 * "{"Username":"ca4f4cff-0d3d-41ee-a951-59ea375b4160","Attributes":[{"Name":"sub","Value":"ca4f4cff-0d3d-41ee-a951-59ea375b4160"},{"Name":"custom:roles","Value":"OPERATORE BO"},{"Name":"custom:vendor","Value":"SKILL UP AGENTI;SKILL UP LAMEZIA;SKILL UP MASTER;SKILL UP RENDE 1;SKILL UP RENDE 2;SALA_FROSINONE;SALA MAURIZON;WINWAY;SERVICE;DARCOM;PAPERNEST"},{"Name":"name","Value":"Patrizio"},{"Name":"family_name","Value":"Esposito"},{"Name":"email","Value":"patrizio.esposito@fidasi.it"},{"Name":"custom:company","Value":"Fidasi"}],"UserCreateDate":"2023-06-22T09:44:59.252Z","UserLastModifiedDate":"2023-09-11T07:37:09.103Z","Enabled":true,"UserStatus":"CONFIRMED"}"
                 */
                break;
              case 'AGENZIA':
                if(user.filter(x => x.Name === 'custom:vendor')[0].Value.split(';').includes(_single_user['custom:vendor'])){
                  _usersRemapped.push(_single_user);
                }
                break;
              case 'TEAM LEADER AGENZIA':
                if(user.filter(x => x.Name === 'custom:vendor')[0].Value.split(';').includes(_single_user['custom:vendor'])){
                  _usersRemapped.push(_single_user);
                }
                break;
              default:
                _usersRemapped.push(_single_user);
                setUserRemap([..._usersRemapped]);
                break;
                
            }
        }
        setUsers([..._usersRemapped.filter(x => x['custom:company'] === 'Fidasi')]);
        setUserRemap([..._usersRemapped]);
      } 
      
  }
  const getRoleTable = async() => {
    const attributes = await AuthHelper.getUserFromIdToken();
    if(attributes.UserAttributes.filter(x => x.Name === 'custom:roles')[0].Value === 'ADMIN' || attributes.UserAttributes.filter(x => x.Name === 'custom:roles')[0].Value === 'OPERATORE BO')setShowAdminTable(true);    
  }
  const getCustomers = async() => {
    const attributes = await AuthHelper.getUserFromIdToken();
    const alreadyExist = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : false;
    setUserAttributes(...[attributes.UserAttributes[1].Value, attributes.UserAttributes[2].Value]);
    let res;
    const vendor1 = attributes.UserAttributes.filter(x => x.Name === 'custom:vendor')[0];
    const vendor2 = attributes.UserAttributes.filter(x => x.Name === 'custom:vendor2')[0];

    switch(attributes.UserAttributes.filter(x => x.Name === 'custom:roles')[0].Value){
      case 'AGENTE': 
        res = await axios.get(`https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/contracts/telefonia?assigned=${alreadyExist ? alreadyExist.Username : attributes.UserAttributes.filter(x => x.Name === 'sub')[0].Value}&role=AGENTE`);
        break;
      case 'AGENZIA':
        res = await axios.get(`https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/contracts/telefonia/?agency=${encodeURIComponent(vendor1.Value)}&role=AGENZIA`)
        break;
      case 'TEAM LEADER AGENZIA':
        res = await axios.get(`https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/contracts/telefonia/?agency=${encodeURIComponent(vendor1.Value.split(';'))}&role=TEAM LEADER AGENZIA`)
        break;
      case 'OPERATORE BO':
        res = await axios.get(`https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/contracts/telefonia/?agency=${vendor2 ? [...vendor1.Value.split(';').filter(x => x !== 'CALL&CALL').map(x => encodeURIComponent(x)), ...vendor2.Value.split(';').filter(x => x !== 'CALL&CALL').map(x => encodeURIComponent(x))] : [...vendor1.Value.split(';').filter(x => x !== 'CALL&CALL').map(x => encodeURIComponent(x))]}&role=OPERATORE BO`)
        break;
      default :
        res = await axios.get(`https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/contracts/telefonia`);
        break;

    }
    // res = await axios.get('https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/contracts');
    const unzipped = await GzipHelper.unzip(res.data);
    setCustomers([...unzipped]);
    setLoading(false);
  }

    useEffect(() => {
        // getCustomers();
        getUsers();
        getCommittenti();
        getRole();
        getRoleTable();
    }, [])
    const handleClick = (data = undefined, name = null) => {
        store.dispatch(clear())
        builder('leads.add');
        if(data){
            Object.keys(data.row).forEach((key) => {
                store.dispatch(setFieldState({name: key, value: data.row[key]}));
            });
        }
        pushMobileOpen(true, 500, "Crea Lead");

    }
    const editContractField = (key, value) => {
      let contract = {...contractSelected};
      contract[key] = value;
      
      setContractSelected({...contract});
    }
 
   const searchContracts = async(x) => {
      setLoading(true);
      const attributes = await AuthHelper.getUserFromIdToken();
      const vendor1 = attributes.UserAttributes.filter(x => x.Name === 'custom:vendor')[0];
      const vendor2 = attributes.UserAttributes.filter(x => x.Name === 'custom:vendor2')[0];
      console.log(vendor1, vendor2);
      let dateTo = dateFilter.to;

      const res = await axios.get(`https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/contracts/telefonia?agency=${vendor2 ? [...vendor1.Value.split(';').map(x => encodeURIComponent(x)), ...vendor2.Value.split(';').map(x => encodeURIComponent(x))] : vendor1.Value.split(';').map(x => encodeURIComponent(x))}&role=${role}&from=${dateFilter.from.format('YYYY-MM-DD')}&to=${dateTo.add(1, 'days').format('YYYY-MM-DD')}&status=${status}&customer=${selectedCommittente}`);
      const unzipped = await GzipHelper.unzip(res.data);
      dateFilter.to.add(-1, 'days');
      console.log(unzipped);
      setCustomers([...unzipped]);
      setLoading(false);
   }

    const ref = useRef();

    const [backdropOpen, setBackdropOpen] = useState(false);

    const [contractSelected, setContractSelected] = useState({});

    const [contractEdit, setContractEdit] = useState(false);

    const [actionLoading, setActionLoading] = useState(false);

    const [openExport, setOpenExport] = useState(false);
    const [exportConditions, setExportConditions] = useState([]);

    const exportExceptions = {
      "status_maurizon": statuses_admin,
      "agency": agenzie,
      "Tipologia Cliente": [
        "Consumer",
        "Business"
      ],
      "Tipologia fornitura": [
        "Power",
        "Gas"
      ]
    }
    const prepopulatedValue = [
      'Committente',
      'Stato Maurizon'
    ]

    const openAddContract = (user) => {
      store.dispatch(clear())
      builder('contracts.add');
      store.dispatch(clear())

      if(store.getState().elements.length === 0){
          builder('contracts.add');
      }
      store.dispatch((setFieldState({name: 'id', value: user.lead})));
      store.dispatch((setFieldState({name: 'Denominazione', value: `${user.Denominazione}`})));
      store.dispatch((setFieldState({name: 'P.IVA', value: `${user['P.I/Codice Fiscale']}`})));
      store.dispatch((setFieldState({name: 'Committente', value: `${user?.Committente}`})));
      store.dispatch((setFieldState({name: 'Tipologia Cliente', value: `${user['Tipologia Cliente']}`})))
      store.dispatch((setFieldState({name: 'Indirizzo', value: `${user['Indirizzo']}`})))
      store.dispatch((setFieldState({name: 'CAP', value: `${user['CAP']}`})))
      store.dispatch((setFieldState({name: 'Provincia', value: `${user['Provincia']}`})))
      store.dispatch((setFieldState({name: 'Bolletta Web', value: `${user['Bolletta Web']}`})))

      window.setTimeout(() => {
        pushMobileOpen(true, 500, "Aggiungi contratto", null, refs);
        document.querySelector('[name="radio-buttons-group"]').click();
      }, 200)

    }
    const ChangeExportCondition = (val, head, idx) => {
      let exp = [...exportConditions];
      exp[idx][head] = val;
      setExportConditions([...exp]);
    }
    return (
    
    <Container sx={{paddingLeft: {
      sx: 12,
      }, paddingRight: {
        sx: 12,
      }, minWidth: '100vw', marginTop: '3rem'}}>
        {/* EXPORT */}
        <Modal
          open={openExport}
          onClose={() => setOpenExport(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Produci Export
            </Typography>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: 12, paddingTop: 8}}>
                <LocalizationProvider dateAdapter={AdapterMoment}  adapterLocale="it">
                    <DatePicker value={dateFilter.from !== '' ? dateFilter.from : new Date()} onChange={(e) => setDateFilter({...dateFilter, from: e})} renderInput={(props) => <TextField {...props} size="small" sx={{width: 150, mr: 2}}/>} />
                </LocalizationProvider>
                -
                <LocalizationProvider dateAdapter={AdapterMoment}  adapterLocale="it">
                    <DatePicker value={dateFilter.to !== '' ? dateFilter.to : new Date()} onChange={(e) => setDateFilter({...dateFilter, to: e})} renderInput={(props) => <TextField {...props} size="small" sx={{width: 150, ml: 2}}/>} />
                </LocalizationProvider>
            </div>
            <Typography variant="h6">Committente</Typography>
            <Select
              fullWidth
              labelId="select-committente-label"
              id="select-committente"
              value={selectedCommittente}
              label="Seleziona committente"
              disabled={role !== 'ADMIN' && role !== 'OPERATORE BO' && role !== 'AGENZIA' && role !== 'TEAM LEADER AGENZIA' ? status === "" || dateFilter.from === "" || dateFilter.to === "" : dateFilter.from === "" || dateFilter.to === ""}
              onChange={(e) => {
                setSelectedCommittente(e.target.value)
              }}
              sx={{color: '#000'}}
            >
              {committenti.filter(x => x['Visibile contratto'] === 'S').map(x => {
              return (
                    <MenuItem value={x.name}>{x.name}</MenuItem>
                  // <Button variant="contained" color="secondary" sx={{ml: 2, mb: 2}} onClick={() => setSelectedCommittente(x.name)}>{x.name}</Button>
                
              )})}
            </Select>
            <Typography variant="h6">Condizioni</Typography>
            {exportConditions.map((condition, idx) => (
              <div style={{display: 'flex', justifyContent: 'space-between', paddingBottom: 8}}>
                <Select value={exportConditions[idx].column} onChange={(e) => ChangeExportCondition(e.target.value, 'column', idx)} sx={{minWidth: 150, color: '#000'}}>{columnsAdmin.filter(column => includeInExportConditions.includes(column.headerName)).map((column) => (
                  <MenuItem value={column.field}>{column.headerName}</MenuItem>
                ))}</Select>
                <Select onChange={(e) => ChangeExportCondition(e.target.value, 'operator', idx)}  sx={{minWidth: 150, color: '#000'}}>
                  <MenuItem value="contains">Contiene</MenuItem>
                  <MenuItem value="equal">Uguale</MenuItem>
                  <MenuItem value="notEqual">Diverso da</MenuItem>
                </Select>
                {exportExceptions[exportConditions[idx].column] ? <Select sx={{minWidth: 200, color: '#000'}} value={exportConditions[idx].value} onChange={(e) => ChangeExportCondition(e.target.value, 'value', idx)}>
                  {exportExceptions[exportConditions[idx].column].map((item) => <MenuItem value={item}>{item}</MenuItem>)}
                </Select>
                : <TextField variant="outlined" label="Valore" onChange={(e) => ChangeExportCondition(e.target.value, 'value', idx)} ></TextField>
                }
                <IconButton onClick={() => setExportConditions([...exportConditions.filter(x => x !== condition)])}><RemoveCircleIcon /></IconButton>
              </div>
            ))}
            <IconButton onClick={() => setExportConditions([...exportConditions, {
              column: "",
              operator: "",
              value: ""
            }])}><AddCircleIcon /></IconButton>

            <div style={{width: '100%', paddingTop: 24}}>
              <Button variant="contained" onClick={async() => {
                try{
                  const userId = await AuthHelper.getUserFromIdToken();
                  
                  const res = await fetch('https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/contracts/export',
                  {
                    method: 'POST',
                    body: JSON.stringify({columns: [...exportConditions], date: dateFilter, matricola: userId.Username, committente: selectedCommittente})
                  });
                  addAlert({
                    message: 'Richiesta di export inviata con successo.',
                    severity: 'success'
                  });
                  setOpenExport(false);
                }catch(ex){
                  addAlert({
                    message: 'Richiesta di export inviata con successo.',
                    severity: 'success'
                  });
                  setOpenExport(false);
                }
              }}>Esporta</Button>
            </div>
          </Box>
        </Modal>
        {/* FINE EXPORT */}
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropOpen}
        >
          { !contractEdit && <Card sx={{minWidth: 500, maxHeight: 400, color: '#000', padding: 8, position: 'relative'}}>
            <CancelIcon htmlColor="#ee0000" sx={{position: 'absolute', top: 0, right: 0, zIndex: 999, '&:hover': {
              cursor: 'pointer'
            }}} onClick={() => {setBackdropOpen(false); setContractSelected({})}}/>
            <h3>Contratto {contractSelected.denominazione} - {contractSelected.Offerta}</h3>

            {selectedContractLead && <>
              <div style={{display: 'flex'}}>
                  <strong>Nome</strong>: {contractSelected.denominazione}
              </div>
              <div style={{display: 'flex'}}>
                  <strong>E-mail</strong>: {selectedContractLead.email}
              </div>
              <div style={{display: 'flex'}}>
                  <strong>Numero di telefono</strong>: {selectedContractLead.phone}
              </div>
              {/* <div style={{display: 'flex'}}>
                  <strong>Presa ultima privacy</strong>: {moment(selectedContractLead?.privacies?.Fidasi[0]?.privacy_1?.expiry).subtract(6, 'months').format('DD/MM/YYYY HH:mm')}
              </div>
              <div style={{display: 'flex'}}>
                  <strong>Scadenza ultima privacy</strong>: {moment(selectedContractLead?.privacies?.Fidasi[0]?.privacy_1?.expiry).format('DD/MM/YYYY HH:mm')}
              </div> */}
            </>}
            {Object.keys(contractSelected).map(contractField => (
              !excludeFromView.includes(contractField) && ( <div style={{display: 'flex'}}>
                <strong>{contractField}</strong>: {contractSelected[contractField]}
              </div>)
            ))}
            {excludeFromView.map(key => (<div style={{display: 'flex'}}>
                <strong>{key}</strong>: {contractSelected[key]}
              </div>
            ))}
            
          </Card>}
          { contractEdit && <Card sx={{minWidth: 500, maxHeight: 400, color: '#000', padding: 8, position: 'relative'}}>
          <CancelIcon htmlColor="#ee0000" sx={{position: 'absolute', top: 0, right: 0, zIndex: 999, '&:hover': {
              cursor: 'pointer'
            }}} onClick={() => {setBackdropOpen(false); setContractSelected({})}}/>
          <h3>Modifica contratto {contractSelected.denominazione} - {contractSelected.Offerta}</h3>
          <div style={{display: 'flex'}}>
              <Typography variant="body2" fontWeight={'bold'} color="#3f3f3f" marginTop={2} sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center'
                          }}>
                              Note stato Maurizon: 
              </Typography>
              <TextField variant="outlined"  size="small" InputLabelProps={{
                          sx: {
                              color: "#444",

                          },
                          shrink: true
                          }}
                          value={contractSelected['Note stato maurizon']}
                          inputProps={{style: {color: '#000'}}} 
                          style={{color: '#000'}} 
                          onChange={(e) => editContractField('Note stato maurizon', e.target.value)}
                          sx={{color: '#000', marginBottom: 1,marginTop: '1rem', marginLeft: 1}} />
          </div>
          <div style={{display: 'flex'}}>
                            <Typography variant="body2" fontWeight={'bold'} color="#3f3f3f" marginTop={2} sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center'
                                        }}>
                                            Plico: 
                            </Typography>
                            <TextField variant="outlined"  size="small" InputLabelProps={{
                                                                                                                sx: {
                                                                                                                    color: "#444",

                                                                                                                },
                                                                                                                shrink: true
                                                                                                            }}
                                                                    value={contractSelected['Plico']}
                                                                    inputProps={{style: {color: '#000'}}} 
                                                                    style={{color: '#000'}} 
                                                                    onChange={(e) => editContractField('Plico', e.target.value)}
                                                                    sx={{color: '#000', marginBottom: 1,marginTop: '1rem', marginLeft: 1}} />
          </div>
            <div style={{display: 'flex', flexDirection:'row', color: '#000', alignItems: 'center'}}><strong>Stato Maurizon:</strong> <Select value={contractSelected.status_maurizon} disabled={contractSelected.status_maurizon === 'CARICATO' || role === 'TEAM LEADER AGENZIA'} size="small" sx={{width: 200, color: '#000', ml: 1}} onChange={(e) => editContractField('status_maurizon', e.target.value)}>
                {role === 'AGENTE' && statuses_agent.map(x => <MenuItem value={x}>{x}</MenuItem>)}
                {role === 'AGENZIA' && statuses_agency.map(x => <MenuItem value={x}>{x}</MenuItem>)}
                {(role === 'ADMIN' || role === 'OPERATORE BO') && statuses_admin.map(x => <MenuItem value={x}>{x}</MenuItem>)}
                {role === 'BO AGENZIA' && statuses_bo_agenzia.map(x => <MenuItem value={x}>{x}</MenuItem>)}

            </Select></div><br />
            <LoadingButton loading={actionLoading} variant="contained" color="primary" onClick={async () => 
            {
            setActionLoading(false);
            try{
              
                const res = await fetch(`https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/contract/edit/${contractSelected.id}`, {
                    method: 'PUT',
                    body: JSON.stringify({...contractSelected, matricola: (await AuthHelper.getUserFromIdToken()).UserAttributes.filter(x => x.Name === 'sub')[0].Value.split('-')[0], Plico: contractSelected.Plico?.trim().replaceAll(' ', '')})
                });
                addAlert({
                    message: 'Il contratto è stato modificato',
                    severity: 'success'
                });
                let _contracts = [...customers];
                _contracts[_contracts.indexOf(_contracts.filter(x => x.id === contractSelected.id)[0])] = {...contractSelected};
                setCustomers([..._contracts]);
                setBackdropOpen(false);
                setActionLoading(false);
            }catch(ex){
                console.log('errore', ex);
                setActionLoading(false)
                addAlert({
                    message: 'Errore server.',
                    severity: 'error'
                });
            }}}>Salva contratto</LoadingButton>
          </Card>}
        </Backdrop>
         {(role !== 'AGENTE') && <Button variant="contained" color="primary" disabled={selectedContracts.length < 1} sx={{float: 'right'}} onClick={() => setOpen(true)}>Assegna contratti</Button>}
        <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Scegli un utente
          </Typography>
          <Autocomplete
              id="user-selection"
              sx={{ width: 300, mt: 2 }}
              options={users}
              autoHighlight
              getOptionLabel={(option) => `${option.name} ${option.family_name}`}
              value={matricola}
              onChange={(e, newValue) => setMatricola(newValue)}
              renderOption={(props, option) => {
                return (
                  <MenuItem {...props}>{props.key}</MenuItem>
                );
              }}
              renderInput={(params) => (
                  <TextField
                  {...params}
                  label="Seleziona un utente"
                  />
              )}
              />
          {/* <MenuList>
            {users.map(x => 
                <MenuItem onClick={() => addToList(x)}>{x.name}</MenuItem>    
            )}
          </MenuList> */}
          <div style={{display: 'flex', marginTop: 12, justifyContent: 'space-between'}} ><LoadingButton variant="contained" color="primary" onClick={() => assignContracts()} loading={loadingButton}>Assegna</LoadingButton><Button variant="contained" color="secondary" onClick={() => setOpen(false)}>Chiudi</Button></div>
        </Box>
      </Modal>
    <Typography variant="h4" color="primary" fontWeight="bold" textAlign="left" sx={{paddingBottom: '2rem', fontWeight: 'bold'}}>Lista Contratti</Typography>
    <BrowserView>

    {role !== 'AGENTE' && <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', marginBottom: '3rem'}}>
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <LocalizationProvider dateAdapter={AdapterMoment}  adapterLocale="it">
              <DatePicker value={dateFilter.from !== '' ? dateFilter.from : new Date()} onChange={(e) => setDateFilter({...dateFilter, from: e})} renderInput={(props) => <TextField {...props} size="small" sx={{width: 150, mr: 2}}/>} />
          </LocalizationProvider>
          -
          <LocalizationProvider dateAdapter={AdapterMoment}  adapterLocale="it">
              <DatePicker value={dateFilter.to !== '' ? dateFilter.to : new Date()} onChange={(e) => setDateFilter({...dateFilter, to: e})} renderInput={(props) => <TextField {...props} size="small" sx={{width: 150, ml: 2}}/>} />
          </LocalizationProvider>
      </div>
        
      <>
        <Box sx={{ minWidth: 300, ml: 2}}>
          <FormControl fullWidth size="small">
            <InputLabel id="select-committente-label">Seleziona stato maurizon</InputLabel>
            <Select
              labelId="select-committente-label"
              id="select-committente"
              value={status}
              label="Seleziona stato maurizon"
              onChange={(e) => setStatus(e.target.value)}
              sx={{color: '#000'}}
            >
              <MenuItem value={""}>Nessuno</MenuItem>
              {statuses_admin.map(x => {
              return (
                    <MenuItem value={x}>{x}</MenuItem>
                  // <Button variant="contained" color="secondary" sx={{ml: 2, mb: 2}} onClick={() => setSelectedCommittente(x.name)}>{x.name}</Button>
                
              )})}
            </Select>
          </FormControl>
        </Box>
      </>
      <>
        <Box sx={{ minWidth: 300, ml: 2}}>
          <FormControl fullWidth size="small">
            <InputLabel id="select-committente-label">Seleziona committente</InputLabel>
            <Select
              labelId="select-committente-label"
              id="select-committente"
              value={selectedCommittente}
              label="Seleziona committente"
              disabled={role !== 'ADMIN' && role !== 'OPERATORE BO' && role !== 'TEAM LEADER AGENZIA' ? status === "" || dateFilter.from === "" || dateFilter.to === "" : dateFilter.from === "" || dateFilter.to === ""}
              onChange={(e) => setSelectedCommittente(e.target.value)}
              sx={{color: '#000'}}
            >
              {committenti.filter(x => x['Visibile contratto'] === 'S').map(x => {
              return (
                    <MenuItem value={x.name}>{x.name}</MenuItem>
                  // <Button variant="contained" color="secondary" sx={{ml: 2, mb: 2}} onClick={() => setSelectedCommittente(x.name)}>{x.name}</Button>
                
              )})}
            </Select>
          </FormControl>
        </Box>
      </>

      <LoadingButton loading={loading} size="small" variant="contained" sx={{ml: 2}} onClick={() => searchContracts()}>Cerca</LoadingButton>
    </div>}
    
    {role === 'AGENTE' && <Box sx={{ width: 300}}>
          <FormControl fullWidth size="small">
            <InputLabel id="select-committente-label">Seleziona committente</InputLabel>
            <Select
              labelId="select-committente-label"
              id="select-committente"
              value={selectedCommittente}
              label="Seleziona committente"
              onChange={(e) => setSelectedCommittente(e.target.value)}
              sx={{color: '#000', mb: 2}}
            >
              {committenti.filter(x => x['Visibile contratto'] === 'S').map(x => {
              return (
                    <MenuItem value={x.name}>{x.name}</MenuItem>
                  // <Button variant="contained" color="secondary" sx={{ml: 2, mb: 2}} onClick={() => setSelectedCommittente(x.name)}>{x.name}</Button>
                
              )})}
            </Select>
          </FormControl>
      </Box>}
    </BrowserView>
    <MobileView>
      {role !== 'AGENTE' && <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', marginBottom: '3rem'}}>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <LocalizationProvider dateAdapter={AdapterMoment}  adapterLocale="it">
                <DatePicker value={dateFilter.from !== '' ? dateFilter.from : new Date()} onChange={(e) => setDateFilter({...dateFilter, from: e})} renderInput={(props) => <TextField {...props} size="small" sx={{width: 150}}/>} />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}  adapterLocale="it">
                <DatePicker value={dateFilter.to !== '' ? dateFilter.to : new Date()} onChange={(e) => setDateFilter({...dateFilter, to: e})} renderInput={(props) => <TextField {...props} size="small" sx={{width: 150, mt: 2}}/>} />
            </LocalizationProvider>
        </div>
          
        <>
          <Box sx={{ minWidth: 300, mt: 2}}>
            <FormControl fullWidth size="small">
              <InputLabel id="select-committente-label">Seleziona stato maurizon</InputLabel>
              <Select
                labelId="select-committente-label"
                id="select-committente"
                value={status}
                label="Seleziona stato maurizon"
                onChange={(e) => setStatus(e.target.value)}
                sx={{color: '#000'}}
              >
                <MenuItem value={""}>Nessuno</MenuItem>
                {statuses_admin.map(x => {
                return (
                      <MenuItem value={x}>{x}</MenuItem>
                    // <Button variant="contained" color="secondary" sx={{ml: 2, mb: 2}} onClick={() => setSelectedCommittente(x.name)}>{x.name}</Button>
                  
                )})}
              </Select>
            </FormControl>
          </Box>
        </>
        <>
          <Box sx={{ minWidth: 300, mt: 2}}>
            <FormControl fullWidth size="small">
              <InputLabel id="select-committente-label">Seleziona committente</InputLabel>
              <Select
                labelId="select-committente-label"
                id="select-committente"
                value={selectedCommittente}
                label="Seleziona committente"
                // disabled={role !== 'ADMIN' && role !== 'OPERATORE BO' &&  role !== 'TEAM LEADER AGENZIA' ? status === "" || dateFilter.from === "" || dateFilter.to === "" : dateFilter.from === "" || dateFilter.to === ""}
                onChange={(e) => setSelectedCommittente(e.target.value)}
                sx={{color: '#000'}}
              >
                {committenti.filter(x => x['Visibile contratto'] === 'S').map(x => {
                return (
                      <MenuItem value={x.name}>{x.name}</MenuItem>
                    // <Button variant="contained" color="secondary" sx={{ml: 2, mb: 2}} onClick={() => setSelectedCommittente(x.name)}>{x.name}</Button>
                  
                )})}
              </Select>
            </FormControl>
          </Box>
        </>

        <LoadingButton fullWidth loading={loading} size="small" variant="contained" sx={{mt: 2, width: '50%'}} onClick={() => searchContracts()}>Cerca</LoadingButton>
      </div>}
    </MobileView>
    {/* {role === 'ADMIN' && <div style={{display: 'flex', justifyContent: 'flex-start'}}>
      <Button type="button" onClick={() => setOpenExport(true)}>Export DB</Button>
    </div>} */}
    {loading && <ListSkeleton />}
    {!loading && customers.length > 0 && <DataTable 
    ref={ref}
    noToolbar={role === 'ADMIN' || role === 'OPERATORE BO'? false : true}
    onSelectionChange={(e) => setSelectedContracts([...e])}
    data={customers.map(x => ({...x, createdAt: moment(x.createdAt).format('DD/MM/YYYY HH:mm:ss')})).filter(x => x.Committente === selectedCommittente).filter(x => x.status_maurizon === 'DA VERIFICARE BO' ? role === 'ADMIN' || role === 'OPERATORE_BO' : true)} 
    columns={columnsAdmin}
    onClick={{action: 'navigate', path: ':id'}}
      initialState={{
        sorting: {
          sortModel: [{field: 'createdAt', sort: 'desc'}]
        }
      }}
    
    />}
 
    {/* <FloatingActionButtons onClick={() => handleClick()}/> */}
    </Container>);
}

export default ListTelefonia;