import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { useNavigate } from "react-router-dom";

import Logo from '../../assets/images/logo.png';
import LogoFidasi from '../../assets/images/fidasi-logo.webp'
import { Backdrop, Card, CardHeader, CircularProgress } from '@mui/material';
import { AuthHelper } from '../../../src/views/controllers/AuthHelper';
import { Config } from '../../misc/config';
import { ServerConfig } from '../../connectors/Config';
import { useSnackBar } from '../providers/consumers/useSnackBar';
import { useSelector } from "react-redux";
import store, { setPlatform, setSub } from '../store/formStore';



const authHelper = AuthHelper.getInstance();

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const sub = useSelector(state => state.subSelected);

  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const [userId, setUserId] = React.useState("");

  const [showAdminMenu, setShowAdminMenu] = React.useState(false);
  const [menuToShow, setMenuToShow] = React.useState("");

  const [platform, setPlatform] = React.useState("Lead4life");
  const [role, setRole] = React.useState("");

  const [loading, setLoading] = React.useState(false);
  const getRoleMenu = async() => {
    let user = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : null;
    const attributes = await AuthHelper.getUserFromIdToken();
    console.log('ATTRIBUTI', attributes);
    if(user && user.length > 0 ? user.Attributes.filter(x => x.Name === 'custom:roles')[0].Value === 'ADMIN' : attributes.UserAttributes.filter(x => x.Name === 'custom:roles')[0].Value === 'ADMIN')setShowAdminMenu(true);
    setMenuToShow(user && user.length > 0 ?  user.Attributes.filter(x => x.Name === 'custom:company')[0].Value : attributes.UserAttributes.filter(x => x.Name === 'custom:company')[0].Value);
    setPlatform(user && user.length > 0 ?  user.Attributes.filter(x => x.Name === 'custom:company')[0].Value : attributes.UserAttributes.filter(x => x.Name === 'custom:company')[0].Value)
    setRole(user && user.length > 0 ?  user.Attributes.filter(x => x.Name === 'custom:roles')[0].Value : attributes.UserAttributes.filter(x => x.Name === 'custom:roles')[0].Value);
  }
  React.useEffect(() => {
      AuthHelper.getUserFromIdToken().then((res) => setUserId(res.UserAttributes.filter(x => x.Name === 'sub')[0].Value.split('-')[0]));
      getRoleMenu();
  }, [])

  const pages = [
    {label: 'Dashboard', canRead: false},
    {label: 'Committenti', canRead: false}, 
    {label: 'Leads', canRead: 'Oxygen'}, 
    {label: "Contratti", canRead: 'Oxygen'}, 
    {label: 'Telefonia', canRead: 'Oxygen'},
    // {label: "Segnalazioni", canRead: 'Fidasi'}, 
    {label: 'Utenti', canRead: false},
    {label: "Esportazioni", canRead: 'Oxygen'}
  ];
  const settings = [{name: 'Dashboard'}, {name: 'Profilo'}];

  const { addAlert } = useSnackBar();


  const switchUser = async() => {
    setLoading(true);
    const email = (await AuthHelper.getUserFromIdToken()).UserAttributes.filter(x => x.Name === 'email')[0].Value;
    const response = await AuthHelper.getUserByEmail(email.includes('fidasi') ? email.replace('fidasi', 'lead4life') : email.replace('lead4life', 'fidasi'), menuToShow === 'Lead4life' ? 'Fidasi' : 'Lead4life');
    sessionStorage.setItem('user', JSON.stringify(response));
    setMenuToShow(menuToShow === 'Lead4life' ? 'Fidasi' : 'Lead4life');
    setPlatform(platform === 'Lead4life' ? 'Fidasi' : 'Lead4life')
    if(!response) addAlert({
      message: 'Errore nello switch',
      severity: 'error'
    });
    else addAlert({
      message: 'Switch effettuato con successo',
      severity: 'success'
    });
    setLoading(false);
  }
  return (
    <AppBar position="static" sx={{paddingLeft: '1rem', paddingRight: '1rem', backgroundColor: platform === 'Oxygen' ? 'rgb(46, 63, 80)' : '#E5007E'}}>
        <Toolbar disableGutters>
          {platform === 'Oxygen' ? <h2 style={{paddingRight: {
            xs: '1rem',
            md: '3rem'
          }
          }}>Leads</h2> : <h2 style={{paddingRight: {
            xs: '1rem',
            md: '3rem'
          }
          }}>Oxygen</h2>}
          
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
                paddingLeft: '2rem'
              }}
            >
              {pages.map((page) => (
                page.canRead === true || page.canRead === menuToShow ? 
                <MenuItem key={page.label} onClick={() => page.label.toLocaleLowerCase() === 'dashboard' ? console.log('ciao') : navigate(page.label.toLowerCase())}>
                  <Typography textAlign="center">{page.label}</Typography>
                </MenuItem> : null
              ))}
            </Menu>
          </Box>
          {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            LOGO
          </Typography> */}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              page.canRead === true || page.canRead === menuToShow || (!page.canRead && showAdminMenu) || page.roleException === role ?
              <Button
                key={page.label}
                onClick={() => page.label.toLowerCase() === 'dashboard' ? navigate('/') : navigate(page.label.toLowerCase())}
                sx={{ my: 2, color: 'white', display: 'block', fontSize: 14, fontWeight: 'regular', fontVariant: "all-small-caps"}}
              >
                {page.label}
              </Button> : null
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Apri le impostazioni" sx={{
              border: '1px solid #000',
              width: 300
            }}>
              
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Profilo" src="https://www.pngall.com/wp-content/uploads/12/Avatar-PNG-Image.png" sx={{
                  width: 35, height: 35, backgroundColor: '#e0e0e0',
                  padding: 0.55,

                }} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px',
              borderBottom: '1px solid #eee'
            }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1,position: 'absolute'}}
                open={loading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <div style={{width: 300, height: 100, color: '#000', position: 'relative'}}>
              
                <div style={{display: 'flex', padding: '1rem', alignItems: 'center', borderBottom: '1px solid #eee'}}>
                  <Avatar alt="Profilo" src="https://www.pngall.com/wp-content/uploads/12/Avatar-PNG-Image.png" sx={{
                    width: 50, height: 50, backgroundColor: '#e0e0e0',
                    padding: 0.55,

                  }} />
                  <div style={{paddingLeft: '.5rem', display: 'block'}}>
                    <strong style={{color: 'rgba(0,0,0,.60)'}}>{sub !== "" ? sub.split('-')[0] : userId}</strong>
                    {/* <p style={{margin: 0, paddingTop: 4, fontSize: 12, color: '#8f8f8f'}}>{menuToShow}</p> */}
                  </div>

                </div>
              </div>
              <div style={{borderBottom: '1px solid #eee'}}>
                {/* <MenuItem onClick={() => switchUser()}>
                  Passa a {menuToShow === 'Lead4life' ? 'Contratti' : 'Leads'}
                </MenuItem> */}
                {settings.map((setting) => (
                  <MenuItem key={setting.name} onClick={setting.action ? setting.action : handleCloseUserMenu}>
                    {setting.customElement ? setting.customElement : <Typography textAlign="center" fontSize={15}>{setting.name}</Typography>}
                  </MenuItem>
                ))}
              </div>
              <div style={{paddingTop: '1rem', paddingLeft: '1rem'}}>
                <Button variant="contained" color="primary" onClick={() => authHelper.logout()}>Esci</Button>
              </div>

            </Menu>
          </Box>
        </Toolbar>
    </AppBar>
  );
}

export { ResponsiveAppBar };